.App {
  /* margin: 60px 120px; */
  width: 800px;
  text-align: center;
  margin: 60px auto;
  min-width: 800px;
}

* {
  font-family: 'Courier', Courier, monospace;
}

h1 {
  font-weight: bold; 
  font-size: 18px;
  margin: 0px;
  line-height: 150%;
}

h2 {
  font-weight: bold; 
  font-size: 16px;
  margin: 0px;
  line-height: 150%;
}

p {
  font-size: 16px;
  margin: 0 0 24px 0;
  line-height: 150%;
  white-space: pre-wrap;
}

li {
  margin: 0;
  line-height: 150%;
}

blockquote {
  line-height: 150%; 
  padding: 24px 12px;
  margin: 0 0 24px 0;
  background-color: #fee1ea;
  border: 1px solid black;
}

ul {
  padding: 0 0 0 24px;
}

details:hover {
  cursor: pointer;
}

details > summary {
  list-style-type: '+ ';
}

details[open] > summary {
  list-style-type: '- ';
}

.centered {
  text-align: center;
  align-self: center;
}

.left-align {
  text-align: left;
  align-self: center;
}

.link {
  font-size: 16px; 
  margin: 0px; 
  line-height: 150%;
  color: #2d5cf6;
}

.link:visited {
  color: #E22B5F
}

.link:hover {
  color: #ffffff;
  background-color: #2d5cf6;
}

.adtext {
  margin-bottom: 0;
}

#header {
  display: flex; 
  flex-direction: row;
  gap: 24px;
}